import React from "react";

const SvgArrowGrayLeft = props => (
  <svg {...props} viewBox="0 0 112.2 210.8">
    <g>
      <polygon
        class="st0"
        fill="#DDDDDD"
        points="112.2,105.7 111.9,105.4 111.5,105.7 	"
      />
      <polygon
        class="st0"
        fill="#DDDDDD"
        points="34.1,105.1 111.5,105.1 0,0 0,73.6 33.8,105.4 	"
      />
      <polygon
        class="st1"
        fill="#c4c4c4"
        points="111.9,105.4 111.8,105.4 111.9,105.4 112.2,105.1 111.5,105.1 111.5,105.1 34.1,105.1 33.8,105.4
		0,137.3 0,210.8 111.5,105.7 	"
      />
      <polygon
        class="st1"
        fill="#c4c4c4"
        points="33.8,105.4 34.1,105.1 111.5,105.1 111.5,105.1 34.1,105.1 	"
      />
      <polygon
        class="st1"
        fill="#c4c4c4"
        points="111.9,105.4 111.9,105.4 111.8,105.4 	"
      />
    </g>
  </svg>
);

export default SvgArrowGrayLeft;
