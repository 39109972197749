import React, { useState } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { Container, Col } from "reactstrap";
import HeroCarousel from "../carousel/hero-carousel";
import { animated, useSpring } from "react-spring";
import { Waypoint } from "react-waypoint";

const FEATUREPROPERTY_QUERY = graphql`
  query HomePropertyQuery {
    wpgraphql {
      page(id: "cGFnZTo1") {
        acf_home_fields {
          featuredPropertyHead
          featuredPropertyText
          featuredPropertyButton
          featuredPropertyPath
          featuredPropertyName
          featuredPropertyLocation
          featuredPropertyUnits
        }
      }
      cpt_property(id: "cHJvcGVydHlfcG9ydGZvbGlvOjIxNA==") {
        title
        acf_properties {
          carouselImg {
            image {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1900) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const FeaturedProperty = props => {
  const [on, toggle] = useState(false);
  const featurebar = useSpring({
    opacity: on ? 1 : 0,
    height: on ? "16%" : "0%",
    config: { duration: 800, tension: 1000 }
  });

  return (
    <StaticQuery
      query={FEATUREPROPERTY_QUERY}
      render={data => {
        return (
          <section className="section__featured-properties">
            <Container>
              <Col className="featured__intro">
                <h2 className="heading2">Featured Property</h2>
                <p className="body-copy">
                  {data.wpgraphql.page.acf_home_fields.featuredPropertyText}
                </p>
                <Link
                  className="btn btn-primary d-none d-sm-inline-block"
                  to={data.wpgraphql.page.acf_home_fields.featuredPropertyPath}
                >
                  {data.wpgraphql.page.acf_home_fields.featuredPropertyButton}
                </Link>
              </Col>
            </Container>
            <div className="featured__property">
              <Waypoint
                bottomOffset="30%"
                onEnter={() => {
                  if (!on) toggle(true);
                }}
              />
              <animated.div
                style={featurebar}
                className="featured__property-details"
              >
                <Container>
                  <h2 className="featured__property-name heading2">
                    {data.wpgraphql.page.acf_home_fields.featuredPropertyName}
                  </h2>
                  <h4 className="heading4">
                    {
                      data.wpgraphql.page.acf_home_fields
                        .featuredPropertyLocation
                    }{" "}
                    &nbsp; | &nbsp;{" "}
                    <span className="featured__unit-mix">
                      {
                        data.wpgraphql.page.acf_home_fields
                          .featuredPropertyUnits
                      }
                    </span>
                  </h4>
                </Container>
              </animated.div>
              <div className="featured__property-carousel">
                {data.wpgraphql.cpt_property.acf_properties.carouselImg !=
                  null && (
                    <HeroCarousel
                      items={
                        data.wpgraphql.cpt_property.acf_properties.carouselImg
                      }
                    />
                  )}
              </div>
              <Container>
                <Link
                  className="btn btn-primary d-sm-none"
                  to={data.wpgraphql.page.acf_home_fields.featuredPropertyPath}
                >
                  {data.wpgraphql.page.acf_home_fields.featuredPropertyButton}
                </Link>
              </Container>
            </div>
          </section>
        );
      }}
    />
  );
};

export default FeaturedProperty;
