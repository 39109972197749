import React from "react";

const SideArrow = props => (
  <svg {...props} viewBox="0 0 173.2 346.4">
    <polygon
      fill="#02648E"
      points="173.2,226.5 173.2,346.4 0,173.2 119.9,173.2 "
    />
    <polygon fill="#0076A8" points="173.4,0 173.4,119.9 119.9,173.4 0,173.4 " />
  </svg>
);

export default SideArrow;
