import React, { useState } from "react"
import { animated, useSpring } from "react-spring"
import { Waypoint } from "react-waypoint"

const Chart = () => {
  const [on, toggle] = useState(false)

  const numbers = useSpring({
    config: { duration: 1500 },
    opacity: on ? 1 : 0,
    delay: 500
  })
  const back = useSpring({
    transform: on ? "translate3d(0px, -28px, 0px)" : "translate3d(0,0px,0)",
    opacity: on ? 1 : 0
  })
  const front = useSpring({
    transform: on ? "translate3d(0px, -42px, 0px)" : "translate3d(0,0px,0)",
    opacity: on ? 1 : 0
  })
  const left = useSpring({
    config: { duration: 500 },
    transform: on
      ? "translate3d(0px, -82px, 0px)"
      : "translate3d(10px, 0px, 0)",
    opacity: on ? 1 : 0,
    delay: 300
  })
  const right = useSpring({
    opacity: on ? 1 : 0
  })

  const numbertick = useSpring({
    config: { duration: 1200 },
    number: 25,
    from: { number: 1 },
    opacity: on ? 1 : 0
  })

  return (
    <div>
      <Waypoint
        bottomOffset='20%'
        // bottom of window
        onEnter={() => {
          if (!on) toggle(true)
        }}
      />
      <svg
        id='chart3d'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 725.3 444.09'
      >
        <title>3DChart</title>
        <g id='chart'>
          <animated.g style={right} is='right'>
            <path d='M399.5,267l122.41,120.5v43L400.5,306Z' fill='#6a9eb5' />
            <path
              d='M520.45,375.23l.17,55.56S598.69,410.78,631,367c0,0,22-23.64,13.92-82.44Z'
              fill='#7ab3cc'
            />
            <path
              d='M520.69,388.13C594.92,367.77,645,329.38,645,285.35,645,220,534.84,167,399,167V267.33Z'
              fill='#8dc8e8'
            />
          </animated.g>
          <animated.g style={back} id='back'>
            <path
              d='M399,266.66V166.37c-65.34,0-124.68,12.27-168.73,32.25Z'
              fill='#00426a'
            />
            <path
              d='M399,300.14,230.47,232V198.51L399,266.66Z'
              fill='#023954'
            />
          </animated.g>
          <animated.g style={left} id='left'>
            <path
              d='M164.29,312.07v46.61s-21.4-30.95-9.11-84.49Z'
              fill='#9b9b9b'
            />
            <path
              d='M232.05,196.64c-47.7,21.58-77.52,52.18-77.52,86.16,0,11.45,3.44,22.5,9.76,33l236.26-51Z'
              fill='#afb0b3'
            />
            <path
              d='M400.55,264.79l-236.26,51v42.91l236.26-52.13Z'
              fill='#898989'
            />
          </animated.g>
          <animated.g style={front} id='front'>
            <path
              d='M520.69,387.35v42.48s-41.22,14.27-124.12,14.26c0,0-178,.9-233.87-83.82V317.36Z'
              fill='#036789'
            />
            <path
              d='M399,402.69c44.29,0,85.82-5.66,121.72-15.51L399,266.37l-236.27,51C192.4,366.64,286.9,402.69,399,402.69Z'
              fill='#0076a8'
            />
          </animated.g>
          <animated.g style={numbers} id='numbers'>
            <g id='percent20'>
              <path
                d='M0,42V39.23l6.6-6.85c2.35-2.43,4.57-5,4.57-8.1A3.78,3.78,0,0,0,7.1,20.12,10.15,10.15,0,0,0,.63,22.85v-4.2A13.12,13.12,0,0,1,8,16.08c4.75,0,8.32,2.82,8.32,7.57,0,4.36-2.86,7.39-5.13,9.53L6,38.05H16.84v4Z'
                fill='#afb0b3'
              />
              <path
                d='M31.71,42.76c-5.84,0-10.33-5-10.33-13.4S25.87,16,31.71,16s10.34,5,10.34,13.36S37.59,42.76,31.71,42.76Zm0-22.85c-3.27,0-5.25,3.23-5.25,9.45s2,9.49,5.25,9.49S37,35.62,37,29.36,35,19.91,31.71,19.91Z'
                fill='#afb0b3'
              />
              <path
                d='M52.37,28.77c-3.4,0-5.84-2.73-5.84-6.3s2.44-6.26,5.84-6.26,5.88,2.73,5.88,6.26S55.82,28.77,52.37,28.77Zm0-10c-1.42,0-2.47,1.06-2.47,3.66s1.05,3.7,2.47,3.7,2.52-1.05,2.52-3.7S53.8,18.81,52.37,18.81Zm-.5,24L50.23,42,66.32,16.08l1.68.84Zm14-.25c-3.4,0-5.84-2.73-5.84-6.3S62.46,30,65.86,30s5.88,2.73,5.88,6.26S69.3,42.55,65.86,42.55Zm0-10c-1.43,0-2.48,1-2.48,3.66s1.05,3.69,2.48,3.69,2.52-1,2.52-3.69S67.29,32.59,65.86,32.59Z'
                fill='#afb0b3'
              />
            </g>
            <g id='percent25'>
              <path
                d='M486,120v-2.81l6.6-6.85c2.35-2.43,4.57-4.95,4.57-8.1a3.78,3.78,0,0,0-4.07-4.16,10.15,10.15,0,0,0-6.47,2.73v-4.2A13.14,13.14,0,0,1,494,94.08c4.75,0,8.32,2.82,8.32,7.57,0,4.36-2.86,7.39-5.13,9.53L492,116.05h10.84v4Z'
                fill='#0076a8'
              />
              <path
                d='M515,120.76a11.89,11.89,0,0,1-7.64-2.61V114a10,10,0,0,0,7,3.06,4.87,4.87,0,0,0,5.17-5.08,4.26,4.26,0,0,0-4.54-4.62,4.57,4.57,0,0,0-4.49,2.69l-2.27-1.14V94.84h15.2v4H512.17v6.22a6.37,6.37,0,0,1,4.24-1.39c4.62,0,8,3.19,8,8.19C524.43,117.36,520.27,120.76,515,120.76Z'
                fill='#0076a8'
              />
              <path
                d='M534.51,106.77c-3.4,0-5.84-2.73-5.84-6.3s2.44-6.26,5.84-6.26,5.88,2.73,5.88,6.26S538,106.77,534.51,106.77Zm0-10c-1.43,0-2.48,1.06-2.48,3.66s1.05,3.7,2.48,3.7,2.52-1,2.52-3.7S535.94,96.81,534.51,96.81Zm-.5,24-1.64-.84,16.09-25.88,1.68.84Zm14-.25c-3.4,0-5.83-2.73-5.83-6.3S544.59,108,548,108s5.89,2.73,5.89,6.26S551.44,120.55,548,120.55Zm0-10c-1.42,0-2.47,1-2.47,3.66s1.05,3.69,2.47,3.69,2.53-1,2.53-3.69S549.42,110.59,548,110.59Z'
                fill='#0076a8'
              />
            </g>
            <g id='percent18'>
              <path
                d='M451.19,26V4.79H448.5V1.64L456.07.29V26Z'
                fill='#00426a'
              />
              <path
                d='M472.45,26.76c-5.42,0-9.83-3-9.83-7.6,0-3.53,2.35-6,5.58-7.15a6.12,6.12,0,0,1-4.07-5.75c0-3.7,3.95-6.26,8.32-6.26s8.27,2.56,8.27,6.26a6.12,6.12,0,0,1-4,5.75c3.19,1.14,5.54,3.62,5.54,7.15C482.23,23.78,477.86,26.76,472.45,26.76Zm-4.88-8.07a4.86,4.86,0,0,0,9.71,0,4.86,4.86,0,0,0-9.71,0Zm4.88-15.5a3.39,3.39,0,0,0-3.66,3.53,3.61,3.61,0,0,0,3.66,3.57,3.57,3.57,0,0,0,3.61-3.57A3.38,3.38,0,0,0,472.45,3.19Z'
                fill='#00426a'
              />
              <path
                d='M492.52,12.77c-3.4,0-5.84-2.73-5.84-6.3S489.12.21,492.52.21s5.88,2.73,5.88,6.26S496,12.77,492.52,12.77Zm0-10c-1.43,0-2.48,1.06-2.48,3.66s1,3.7,2.48,3.7S495,9.12,495,6.47,494,2.81,492.52,2.81Zm-.5,24L490.38,26,506.47.08l1.68.84Zm14-.25c-3.41,0-5.84-2.73-5.84-6.3S502.6,14,506,14s5.88,2.73,5.88,6.26S509.45,26.55,506,26.55Zm0-10c-1.43,0-2.48,1.05-2.48,3.66s1.05,3.69,2.48,3.69,2.52-1.05,2.52-3.69S507.43,16.59,506,16.59Z'
                fill='#00426a'
              />
            </g>
            <g id='percent48'>
              <path
                d='M667.55,117.87V124h-4.87v-6.17h-11.6v-2.6l12.48-16.64h4V114.3h4.58v3.57Zm-4.87-12.73-6.85,9.16h6.85Z'
                fill='#8dc8e8'
              />
              <path
                d='M685.86,124.76c-5.42,0-9.83-3-9.83-7.6,0-3.53,2.35-6,5.59-7.15a6.12,6.12,0,0,1-4.08-5.75c0-3.7,4-6.26,8.32-6.26s8.28,2.56,8.28,6.26a6.13,6.13,0,0,1-4,5.75c3.2,1.14,5.55,3.62,5.55,7.15C695.65,121.78,691.28,124.76,685.86,124.76ZM681,116.69a4.85,4.85,0,0,0,9.7,0,4.85,4.85,0,0,0-9.7,0Zm4.87-15.5a3.39,3.39,0,0,0-3.65,3.53,3.63,3.63,0,0,0,7.26,0A3.38,3.38,0,0,0,685.86,101.19Z'
                fill='#8dc8e8'
              />
              <path
                d='M705.94,110.77c-3.41,0-5.84-2.73-5.84-6.3s2.43-6.26,5.84-6.26,5.88,2.73,5.88,6.26S709.38,110.77,705.94,110.77Zm0-10c-1.43,0-2.48,1.06-2.48,3.66s1,3.7,2.48,3.7,2.52-1,2.52-3.7S707.36,100.81,705.94,100.81Zm-.51,24-1.64-.84,16.09-25.88,1.68.84Zm14-.25c-3.4,0-5.84-2.73-5.84-6.3S716,112,719.42,112s5.88,2.73,5.88,6.26S722.87,124.55,719.42,124.55Zm0-10c-1.43,0-2.48,1-2.48,3.66s1,3.69,2.48,3.69,2.52-1,2.52-3.69S720.85,114.59,719.42,114.59Z'
                fill='#8dc8e8'
              />
            </g>
            <path
              id='percent20line'
              d='M202.4,171.59,115.66,29.74H83.53'
              fill='none'
              stroke='#afb0b3'
              stroke-width='2px'
            />
            <path
              id='percent25line'
              d='M404.78,265.69l48.71-157.95h18'
              fill='none'
              stroke='#afb0b3'
              stroke-width='2px'
            />
            <path
              id='percent18line'
              d='M364.4,155.59,414.57,13.74h18.59'
              fill='none'
              stroke='#afb0b3'
              stroke-width='2px'
            />
            <path
              id='percent48line'
              d='M567.4,253.59l50.17-141.85h18.59'
              fill='none'
              stroke='#afb0b3'
              stroke-width='2px'
            />
          </animated.g>
          <g>
            <animated.text
              id='numbertick'
              transform='translate(10 79.6)'
              font-size='50px'
              fill='#ccc'
            >
              {numbertick.number.interpolate(number => Math.floor(number))}
            </animated.text>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default Chart
